import { Text } from '@chakra-ui/react';
import { formatDateInWords } from 'lib';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { LinkArrowIcon } from '../../../public';

export function BlogCardItem({
  bannerImage,
  category,
  title,
  date,
  link,
}: {
  bannerImage: string;
  category: string;
  title: string;
  date: string;
  link: string;
}) {
  const { t, i18n } = useTranslation();

  return (
    <section className="group flex w-full max-w-full cursor-pointer flex-col transition duration-200 ease-in sm:max-w-full">
      <Link href={link} passHref>
        <a className="flex flex-col gap-3">
          <div className="container relative">
            <div className="absolute right-4 top-4 z-[99] hidden group-hover:block">
              <LinkArrowIcon />
            </div>
            <div className="h-[200px] w-full">
              <Image
                src={
                  bannerImage
                    ? bannerImage
                    : `${process.env.NEXT_PUBLIC_STATIC_URL}/news/fallback.png`
                }
                className="group-hover:z-120 m-auto h-[230px] max-h-[200px] w-full
             transform border border-solid border-gray-500 object-cover object-center  transition duration-200  ease-in group-hover:z-50 group-hover:opacity-80"
                alt={`blog news wesionary ${title}`}
                layout="fill"
                priority={false}
                objectFit="cover"
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
              />
            </div>
            <div className="textblock absolute bottom-4 left-2 z-[99] !bg-white p-2">
              <Text className="!text-black-secondary text-base font-semibold">
                {t(category)}
              </Text>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start">
            <div className="flex items-start justify-start gap-3 no-underline">
              <h3 className="font-Poppins m-0 mr-3 line-clamp-2 text-ellipsis text-2xl font-semibold text-[#000]">
                {title}
              </h3>
            </div>
            <p className="m-0 mt-[6px] text-sm text-[#222B45]">
              {formatDateInWords(date, i18n.language)}
            </p>
          </div>
        </a>
      </Link>
    </section>
  );
}
