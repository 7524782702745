import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { CategoryTags } from '../../atoms/CategoryTags';
import { formatDateInWords } from 'lib';
import { useTranslation } from 'next-i18next';

export function BlogListItem({
  bannerImage,
  title,
  link,
  date,
  category,
}: {
  bannerImage: string;
  title: string;
  link: string;
  date: string;
  category: string;
}) {
  const { i18n } = useTranslation();

  return (
    <section className="flex items-start justify-start gap-3">
      <Link href={link}>
        <a className="relative min-h-[91px] !w-[110px] min-w-[110px]">
          <Image
            loading="eager"
            src={bannerImage}
            alt={`latest blog ${title}`}
            objectFit="cover"
            height={350}
            width={450}
            className="transform border border-solid border-gray-500 bg-gray-200/75 object-cover object-center transition duration-200 ease-in hover:opacity-80"
          />
        </a>
      </Link>

      <div className="flex flex-col items-start justify-start">
        <CategoryTags tags={[category]} className="text-[13px] font-medium" />
        <Link href={link}>
          <a className="flex items-start justify-start gap-3 no-underline">
            <h3 className="font-Poppins m-0 line-clamp-2 text-ellipsis text-base font-semibold text-[#000]">
              {title}
            </h3>
          </a>
        </Link>
        <p className="m-0 mt-[6px] text-sm text-[#2E3A59]">
          {formatDateInWords(date, i18n.language)}
        </p>
      </div>
    </section>
  );
}
