import '../styles/globals.css';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { ChakraProvider } from '@chakra-ui/react';
import { appWithTranslation } from 'next-i18next';
import { StickyContainer, Sticky } from 'react-sticky';
import Head from 'next/head';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { ContextProvider } from '../utils/Context';
import App from 'next/app';
import Script from 'next/script';
import { theme } from 'ui/tailwind.config';
import { Footer, Header } from '../components/organisms';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import NextNProgress from 'nextjs-progressbar';

const GOOGLE_ANALYTICS_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID || '';

const queryClient = new QueryClient();

function MyApp({ Component, pageProps }: AppProps) {
  // usePageView();
  const router = useRouter();

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ContextProvider>
          <ChakraProvider theme={theme}>
            <Head>
              <title>wesionaryTEAM</title>
              <link rel="icon" href="/favicon.ico" />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0"
              />
              <script
                type="text/javascript"
                id="hs-script-loader"
                async
                defer
                src={`//js-na1.hs-scripts.com/21921066.js?version=${Date.now()}`}
              ></script>
              {(router?.pathname.startsWith('/contact') ||
                router?.pathname.startsWith('/join-us') ||
                router?.pathname?.startsWith('/report-security')) && (
                <script
                  src={`https://www.google.com/recaptcha/enterprise.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_KEY}`}
                ></script>
              )}

              <meta name="robots" content="index, follow" />
              <meta property="og:locale" content="ja_JP" />
              <meta property="og:type" content="website" />
              <meta property="fb:app_id" content="732526978162737" />
              <meta property="og:image:width" content="1200" />
              <meta property="og:image:height" content="630" />
            </Head>
            {/* @ts-ignore */}
            <StickyContainer>
              {/* @ts-ignore */}
              <Sticky>
                {({ style, distanceFromTop }: any) => {
                  const isActive = Math.abs(distanceFromTop) > 16;
                  return (
                    <header
                      className={`relative z-[100] ${
                        isActive
                          ? 'shadow-[0_0px_8px_0px_rgba(5,5,33,0.15)]'
                          : ''
                      } transition-all duration-500`}
                      style={{ ...style }}
                    >
                      <Header isActive={isActive} />
                    </header>
                  );
                }}
              </Sticky>
              <Component {...pageProps} />
              <NextNProgress color="#3767B1" options={{ showSpinner: false }} />
              <Footer />
            </StickyContainer>
          </ChakraProvider>
        </ContextProvider>
      </QueryClientProvider>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`}
      />
      <Script strategy="lazyOnload" id="googleAnalytics">
        {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${GOOGLE_ANALYTICS_ID}', {
                    page_path: window.location.pathname,
                    });
                `}
      </Script>
    </>
  );
}
MyApp.getInitialProps = async (appContext: any) => {
  const appProps = await App.getInitialProps(appContext);

  return { ...appProps };
};

export default appWithTranslation(MyApp);
