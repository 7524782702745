import { format } from 'date-fns';
import { ja, enUS } from 'date-fns/locale';

export const truncateTextWithLength = (
  text: string,
  characterCount: number
): string => {
  if (!text) return '';
  return text.length > characterCount
    ? `${text.substring(0, characterCount)}...`
    : text;
};

export const formatDate = (date: string) => {
  return format(date, 'MMMM dd, yyyy');
};

export const formatDateTime = (date: string) => {
  return format(date, 'MMMM dd, yyyy hh:mm a');
};

export const formatDateInWords = (date: string, locale?: string) => {
  const lang = locale === 'en' ? enUS : ja;
  const formatStr = locale === 'en' ? 'yyyy MMM' : 'yyyy年 MMM';

  try {
    return format(new Date(date), formatStr, { locale: lang });
  } catch (error) {
    console.error('Invalid date format:', error);
    return date;
  }
};

export const formatFullDateInWords = (date: string, locale?: string) => {
  const formatStr = locale === 'en' ? 'yyyy MMM' : 'yyyy年 M月';
  try {
    return format(new Date(date), formatStr);
  } catch (error) {
    console.error('Invalid date format:', error);
    return date;
  }
};

const BYTE = 1024;
export const getSizeInBytes = (megaBytes: number) =>
  megaBytes * Math.pow(BYTE, 2);
